<template>
  <list-container-widget
    :id="type ? `type-${type.key}` : 'organizations'"
    :app-id="app.id"
    :title="translatedType"
    :type="type.key === 'noKey' ? null : type.key"
    :size="total"
    :stretch="false"
    hidden-actions
    :disableViewMore="true"
  >
    <template #actions>
      <ViewAllButton item-type="organizations" />
    </template>
    <organizations-paginated-table
      v-if="canList"
      :type-key="type && type.key !== 'null' ? type.key : null"
      :listing-type="listingType"
      :load-first-page="loadFirstPage"
      :per-page="perPage"
      :enable-card-size="enableCardSize"
      class="organizations-table"
      :is-societies="false"
      :show-filter=false
      :update-search="updateSearch "
      @total="getTotal"
    />
  </list-container-widget>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import CardWidgetMixin from "@core/mixins/widgets/CardWidgetMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import OrganizationsPaginatedTable from "../components/OrganizationsPaginatedTable.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import MembershipEventCreateModal from "@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue";
import {
  ENABLED_APPS_GETTERS,
} from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: "OrganizationsTableWidget",
  components: { 
    ListContainerWidget, 
    OrganizationsPaginatedTable, 
    // MembershipEventCreateModal,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin, CardWidgetMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
    listingType: {
      type: Number,
      required: true,
    },
    loadFirstPage: {
      type: Boolean,
      default: true,
    },
    // perPage: {
    //   type: Number,
    //   default: 8,
    // },
  },
  data() {
    return {
      perPage: 8,
      total: 0,
      search: '',
      updateSearch: null,
      isCreateModalOpended: false,
      // filters: false,
    };
  },
  computed: {
    canList() {
      return (
        checkPermissions(
          "index",
          `organization`,
          this.loggedMemberRoles,
          this.collective
        ) || this.isStaff
      );
    },
    canAdd() {
      return (
        checkPermissions(
          "create",
          `organization`,
          this.loggedMemberRoles,
          this.collective
        ) || this.isStaff
      );
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    translatedType() {
      if (!this.type && this.app) {
        return this.widgetName;
      }

      if (!this.type || this.type.key === "null") {
        return this.$t("organizations.title");
      }

      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.name
      );
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    noShowOrganizations() {
      if (!this.societies) {
        return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].organizations?.customization?.organizationView === 'none';
      }
      return false;
    },
  },
  methods: {
    getTotal(value) {
      this.total = value;
    },
    handleChanger(search, page = { page: 1 }) {
      this.searchPage = page.page;
      this.search = search;
      this.updateSearch = { searchPage: this.searchPage, search: this.search };
    },
  },
};
</script>

<style lang="scss" scoped>
.organizations-table {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
}
</style>
